.hero {
  width: 100%;
  min-height: 300px;
  border: 1px solid #ddd;
  max-width: 1298px;
  margin: 0 auto;
  border-radius: 6px;
  background-image: url("../assets/img/img1.jpg");
  background-size: cover;
  gap: 12px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.img {
  width: 110px;
}

.heroText {
  width: 30%;
  height: 100%;
  position: absolute;
  font-size: 19px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 767px) {
  .heroText {
    width: 100%;
    height: 40%;
    bottom: 0;
    margin-bottom: 0;
    left: 0;
  }
}

.contentText {
  width: 100%;
  height: 25%;
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  margin-bottom: 0;
  left: 0;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.contentText2 {
  width: 100%;
  height: 14%;
  position: absolute;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  bottom: 0;
  margin-bottom: 0;
  left: 0;
}

.title2 {
  font-size: 14px;
  color:#333;
}

.message {
  font-size: 16px;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
  min-height: 300px;
  max-width: 1298px;
  margin: 0 auto;
}

.card {
  min-height: 300px;
  border: 1px solid #ddd;
  max-width: 1298px;
  margin: 0 auto;
  border-radius: 6px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  gap: 12px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.card1 {
  width: 70%;
  background-image: url("../assets/img/img2.jpg");
}

.card2 {
  width: 30%;
  background-image: url("../assets/img/img3.jpg");
}

.card3 {
  width: 25%;
}

.content1 {
  background-image: url("../assets/img/img4.jpg");
}

.content2 {
  background-image: url("../assets/img/img5.jpg");
}

.content3 {
  background-image: url("../assets/img/img6.jpg");
}

.content4 {
  background-image: url("../assets/img/img7.jpg");
}

@media only screen and (max-width: 767px) {
  .container {
    flex-wrap: wrap;
  }
  .card {
    width: 100% !important;
  }
  .content2 {
    width: 100% !important;
  }
  .content3 {
    width: 100% !important;
  }
}
