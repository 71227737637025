.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#fixedDiv {
  top: 180px;
  position: fixed;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 20px;
  z-index: 100;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  display: flex;
  gap: 14px;
  cursor: pointer;
}

#fixedDiv:hover {
  background: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 767px) {
  #fixedDiv {
    max-width: 90%;
  }
}
